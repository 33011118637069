import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

type IProps = {};
const footerContainerStyle: React.CSSProperties = {
  bottom: 0,
  left: 0,
  //   width: "100%",
  backgroundColor: "#222",
  padding: "1.5rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const footerLinkStyles = {
  fontSize: ".87em",
  color: "#fff",
  padding: 3,
};

const Footer: FC<IProps> = ({}): ReactElement => {
  return (
    <footer style={footerContainerStyle}>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            color: "#aaa",
            margin: "5px 0 8px",
            letterSpacing: "0.02rem",
            fontWeight: 300,
            textAlign: "center",
            fontFamily: "Roboto Serif, sans-serif",
          }}
        >
          2023, One Step Ahead, All Rights Reserved.
        </div>
        <div
          style={{
            color: "#aaa",
            margin: "5px 0 8px",
            letterSpacing: "0.02rem",
            fontWeight: 100,
            fontSize: 12,
            textAlign: "center",
            fontFamily: "Roboto Serif, sans-serif",
            marginBottom: 10,
          }}
        >
          (Inspired by <i>The Nestomir</i> fantasy series)
        </div>

        <a style={footerLinkStyles} target="_blank" href="https://thenestomir.substack.com/">
          Newsletter
        </a>
        <a style={footerLinkStyles} target="_blank" href="https://thenestomir.substack.com/">
          Support{" "}
        </a>
        <a style={footerLinkStyles} target="_blank" href="https://thenestomir.substack.com/">
          Press{" "}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
