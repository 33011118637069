import React, { FC, ReactElement, useState } from "react";
import { useMutation } from "@apollo/client";
import { SAVE_DRAFT } from "../graphql/mutations/saveDraft";
import styled from "styled-components";
import { ThemeProvider } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { MasterDiv, Spacer, ErrorDiv } from "../ui/base";
import { Header, theme, Caption } from "../ui/signup";
import Cover from "../ui/cover";
import Snackbar from "@mui/material/Snackbar";

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Signup: FC = ({}): ReactElement => {
  const [body, setBody] = useState("");
  const [type, setType] = useState(1);
  const [source, setSrc] = useState("");
  const [source_text, setSrcText] = useState("");
  const [code_snippet, setSnippet] = useState("");
  const [drafts_code, setDraftsCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbar, setSnackBar] = useState(false);

  const [saveDraft, { loading, client }] = useMutation(SAVE_DRAFT, {
    onCompleted: () => {
      setBody("");
      setSrc("");
      setSnippet("");
      setSrcText("");
      setErrorMessage("");
      setSnackBar(true);
    },
    errorPolicy: "all",
    onError: (error) => {
      setErrorMessage(error.message);
      window.scrollTo(0, 0);
    },
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const variables = { body, source, code_snippet, source_text, drafts_code };
    if (!body.length) return;
    await saveDraft({ variables });
  };

  return (
    <ThemeProvider theme={theme}>
      <Cover title="Create a Draft" has_graphic={false} />
      <Container>
        <MasterDiv width={60}>
          <Header>Jot down a draft for later</Header>
          <div style={{ height: 10 }} />
          <Caption>Help inspire and teach others...</Caption>
          <Spacer />
          {errorMessage ? <ErrorDiv>{errorMessage}</ErrorDiv> : null}
          <div style={{ height: 10 }} />
          <form onSubmit={(e) => handleSubmit(e)}>
            <TextField helperText={"Enter your secret draft code to post a draft."} onChange={(e) => setDraftsCode(e.target.value)} id="outlined-required" label="Draft Code" defaultValue="" fullWidth variant="standard" />
            <Spacer />
            <Spacer />
            <TextField multiline value={body} rows={8} onChange={(e) => setBody(e.target.value)} id="outlined-required" label="Post body.." defaultValue="" fullWidth />
            <Spacer />
            <TextField multiline value={code_snippet} rows={5} onChange={(e) => setSnippet(e.target.value)} id="outlined-required" label="Code snippet.." defaultValue="" fullWidth />
            <Spacer />
            <TextField value={source_text} multiline rows={5} onChange={(e) => setSrcText(e.target.value)} id="outlined-required" label="Source text.." defaultValue="" fullWidth />
            <Spacer />
            <TextField id="outlined-multiline-static" value={source} label="Source URL" defaultValue="" fullWidth onChange={(e) => setSrc(e.target.value)} />
            <Spacer />
            <Button variant="contained" disableElevation type="submit" value="Submit">
              Submit
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "blue",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "35%",
                  marginLeft: "-12px",
                }}
              />
            )}
          </form>
          <Snackbar message="Draft saved successfully...you can open it on mobile" open={snackbar} autoHideDuration={5000} onClose={() => setSnackBar(false)} />
        </MasterDiv>
      </Container>
    </ThemeProvider>
  );
};

export default Signup;
