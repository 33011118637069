import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import { mediaQuery } from "../styles/base";

const Container = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #c246ef;
  border-style: solid;
  border-color: #c246ef;
  border-top-width: 1px;
  border-bottom-width: 0px;
  /* box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5); */
`;

const Caption = styled.div`
  font-size: 12px;
  font-family: "Roboto Serif", sans-serif;
  color: white;
  text-align: center;
  line-height: 20px;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: 9px;
  }
`;

const Skills: FC = (): ReactElement => {
  return (
    <Container>
      <Caption>Skills shared...</Caption>
      <Caption>Python Pandas</Caption>
      <Caption>Computer Science</Caption>
      <Caption>Supervised ML</Caption>
    </Container>
  );
};

export default Skills;
