import React, { FC, ReactElement } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import StickyChecklist from "../nav/StickyChecklist";
import NavMain from "../nav/_NavMain";
import Home from "../home/_Home";
import Drafts from "../drafts/_Drafts";
import Privacy from "../static/Privacy";
import Footer from "./Footer";

const Router: FC = (): ReactElement => {
  return (
    <BrowserRouter>
      <NavMain />
      {/* <StickyChecklist /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/drafts" element={<Drafts />} />
        <Route path="/privacy_policy" element={<Privacy />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
