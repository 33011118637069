import React, { FC, ReactElement, useState, useEffect } from "react";
import { Version, Header, SubTitle, TinyTitle } from "../styles/home";
import HeroForm from "./HeroForm";
import styled from "styled-components";

const CoverTextDiv = styled.div`
  width: 44.5%;
  @media (max-width: 1000px) {
    width: 90%;
  }
  @media (max-width: 600px) {
    width: 90%;
    margin-top: 30px;
  }
`;

const HeroContainer: FC = (): ReactElement => {
  return (
    <CoverTextDiv>
      <Header>
        <span>One Step Ahead</span>
        <span style={{ fontSize: 11 }}> NYC v 1.0000</span>
      </Header>
      <SubTitle>
        <b></b>
        <span>A REAL WORLD, </span>
        <span style={{ color: "#6886e9", fontSize: 21 }}>
          <span style={{ fontSize: 29 }}>
            <b> RPG upskilling game.</b>
          </span>
        </span>
        <b> Receive guidance </b> from people one step ahead in your career journey. Screw sitting at your desk–go on an adventure 😜.
      </SubTitle>
      <HeroForm />
    </CoverTextDiv>
  );
};

export default HeroContainer;
