import React, { FC, ReactElement } from "react";
import Cover from "../ui/cover";

const Privacy: FC = ({}): ReactElement => {
  return (
    <div>
      <Cover title="Privacy Policy" has_graphic={false} />
      {/* <h1 style={{ textAlign: "center" }}>Privacy Policy</h1> */}
      <ul>
        <li>
          <p>
            Protecting your private information is our priority. This Statement of Privacy applies to the Incubatedd.com website and governs data collection and usage. By using the Incubatedd.com website, you consent to the data practices described
            in this statement.
          </p>
        </li>
        <li>
          <h5>Collection of your Personal Information</h5>
          <p>
            We may request you to provide personally identifiable information, such as your e-mail address, and name. We may also collect anonymous demographic information, which is not unique to you, such as your postal code, age, gender,
            preferences, interests and favorites. If you purchase Incubatedd.com products and services, we collect and store billing and credit card information. We may also contact you via surveys to obtain your opinion of our current services or of
            potential new services that we may offer.
          </p>
          <p>
            Information about your computer hardware and software is collected automatically by Incubatedd.com. This information may include: your IP address, browser type, domain names, access times and referring website addresses. We may use this
            information for the operation of our services, to maintain the quality of our services, and to provide general statistics regarding use of the Incubatedd.com website.
          </p>
          <p>
            Many of our services let you share information with others. Please keep in mind that if you publicly disclose personally identifiable information or personally sensitive data through Incubatedd.com, this information may be seen, collected
            and used by others.
          </p>
          <p>
            We encourage you to review the privacy statements of websites you choose to link to from Incubatedd.com so that you can understand how those websites collect, use and share your information. We are not responsible for the privacy policies
            of outside websites.
          </p>
        </li>
        <li>
          <h5>Use of your Personal Information</h5>
          <p>
            We use your personal information to operate the Incubatedd.com website and deliver the services you have requested. We may use your personally identifiable information to inform you periodically about our services, such as letting you
            know about upcoming changes or improvements. We may also use your personally identifiable information to inform you of other products or services available from Incubatedd.com and our affiliates.
          </p>
          <p>
            We do not sell, rent or lease Incubatedd.com customer lists to third parties. We may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your
            unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party without your consent.
          </p>
          <p>
            In order to provide customer support, perform statistical analysis, perform tasks required to complete a purchase transaction, and send you email or postal mail, we may share data with trusted partners who help us. We prohibit all third
            parties from using your personal information except to provide these services to Incubatedd.com. We require them to maintain the confidentiality of your information. Your personal information may pass through or be housed or processed on
            servers located outside the country where you live.
          </p>
          <p>We do not collect, use or disclose sensitive personal information, such as race, religion, or political affiliations, without your explicit consent.</p>
          <p>
            We keep track of the pages our customers visit and services they use within Incubatedd.com, in order to determine what Incubatedd.com services are the most popular. This data may be used, for example, to deliver customized content and
            advertising within Incubatedd.com to customers whose behavior indicates that they are interested in a particular subject.
          </p>
          <p>We may share aggregated, non-personally identifiable information publicly and with our partners. For example, we may share information publicly to show trends about the general use of our services.</p>
          <p>We will disclose your personal information, without notice, only in the good faith belief that such action is necessary:</p>
          <ul>
            <li>as required by law or to comply with legal process or enforceable governmental request.</li>
            <li>to enforce our Terms of Service.</li>
            <li>to detect, prevent, or otherwise address illegal conduct, fraud, security or technical issues.</li>
            <li>to protect against harm to the rights, property or safety of our users, us or the public.</li>
          </ul>
          <p>
            If we are involved in a sale of Incubatedd.com, we will use our best efforts to ensure the continued confidentiality of any personal information and give our affected users notice before personal information becomes subject to a different
            privacy policy.
          </p>
          <p>
            Otherwise, we will not share your personal information with outside companies, organizations or individuals, unless we have your express consent to do so. We require opt-in consent for the sharing of any sensitive personal information.
          </p>
        </li>
        <li>
          <h5>Your Access to Your Personal Information</h5>
          <p>
            We always aim to provide you with access to your own personal information. If that information is wrong, we strive to give you ways to update it quickly or to delete it – unless we have to keep that information for legitimate business or
            legal purposes. When updating your personal information, we may ask you to verify your identity before we act on your request.
          </p>
          <p>
            We aim to maintain our services in a manner that protects information from accidental or malicious destruction. Because of this, after you delete information from our services, we may not immediately delete residual copies from our active
            servers and may not remove information from our backup systems.
          </p>
        </li>
        <li>
          <h5>Use of Cookies</h5>
          <p>
            The Incubatedd.com website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page or multimedia server. Cookies cannot be used to run programs or deliver
            viruses to your computer. Cookies are uniquely assigned to you, and are designed to only be read by a web server in the domain that issued the cookie to you.
          </p>
          <p>
            One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page or feature. For example, if you personalize
            Incubatedd.com pages or register with the Incubatedd.com site or our services, a cookie helps Incubatedd.com to recall your specific information on subsequent visits. This simplifies the process of recording your personal information.
            When you return to the Incubatedd.com website, the information you previously provided can be retrieved, so you can easily use the Incubatedd.com features that you customized.
          </p>
          <p>
            Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the
            Incubatedd.com website you visit.
          </p>
        </li>
        <li>
          <h5>Security of your Personal Information</h5>
          <p>
            We secure the personally identifiable information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. When personal information (such as a credit card number) is
            transmitted to other websites, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol.
          </p>
        </li>
        <li>
          <h5>Changes to this Statement</h5>
          <p>
            Incubatedd.com may occasionally update this Statement of Privacy to reflect company and customer feedback. We will not reduce your rights under this Statement of Privacy without your explicit consent. We will post any privacy policy
            changes on this page and, if the changes are significant, we will provide a more prominent notice (including, for certain services, email notification of privacy policy changes). We encourage you to periodically review this Statement.
          </p>
        </li>
        <li>
          <h5>Contact Information</h5>
          <p>Incubatedd.com is a service of Relative Emotion Enterprises, Inc. We welcome your comments regarding this Statement of Privacy. If you believe that Incubatedd.com has not adhered to this Statement, please email steven@Incubatedd.com</p>
        </li>
        <li>
          <h5>Effective Date: </h5>
          <p>July 20th, 2013.</p>
        </li>
      </ul>
    </div>
  );
};

export default Privacy;
