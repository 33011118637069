import React, { FC, ReactElement } from "react";
import HeroContainer from "./_HeroContainer";
import Howitworks from "./Howitworks";
import Stats from "./Stats";
import { Spacer } from "../styles/base";
import { mediaQuery } from "../styles/base";
import SkillBand from "./SkillBand";
import styled from "styled-components";

const InnerContainer = styled.div`
  width: 67.5%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 75%;
  }
  @media (max-width: ${mediaQuery.phone}) {
    width: 100%;
  }
`;

const Home: FC = (): ReactElement => {
  return (
    <div>
      <div className="Cover_Home">
        <HeroContainer />
      </div>
      <SkillBand />
      <Stats />
      {/* <div className="TestBG">
        <InnerContainer>
          <Howitworks />
          <Spacer height="0px" />
          <div style={{ display: "flex", justifyContent: "center" }}></div>
        </InnerContainer>
      </div> */}
    </div>
  );
};

export default Home;
