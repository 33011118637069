import React, { FC, ReactElement, useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { SAVE_WAITLISTER } from "../graphql/mutations/saveWaitlister";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import styled from "styled-components";

const Bubble = styled.div`
  background-color: white;
  border-radius: 20px;
  border-color: gold;
  border-style: solid;
  border-width: 2.5px;
  margin-top: 20px;
`;

const HeroForm: FC = (): ReactElement => {
  const [email, setEmail] = useState("");
  const [saveWaitlister] = useMutation(SAVE_WAITLISTER);
  const submit = async (e: any) => {
    if (!email) return;
    e.preventDefault();
    await saveWaitlister({ variables: { email } });
    setEmail("");
  };

  return (
    <div style={{ marginTop: 10 }}>
      <form onSubmit={(e) => submit(e)}>
        <TextField style={{ backgroundColor: "white", borderRadius: 7 }} value={email} onChange={(e) => setEmail(e.target.value)} id="outlined-required" label="...insert email to begin adventure" defaultValue="" fullWidth />
        <Button style={{ width: "100%", marginTop: 10 }} variant="contained" disableElevation type="submit" value="Submit">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default HeroForm;
